/*
#5f6366
#4d6d9a
#86b3d1
#99ced3
#edb5bf
*/

body {
	font-family: Consolas, Lucida Console, Courier New, serif;
	padding: 10px;
	font-size: 90%;
}

html,
button,
input,
select,
textarea,
.pure-g [class*="pure-u"] {
	font-family: Consolas, Lucida Console, Courier New, serif;
}

.heading {
	text-align: center;
}

.body {
	width: 80%;
	margin: 0 auto;
}

h1 {
	color: #5f6366;
	margin: 0;
}

textarea[class="input"] {
	font-family: Consolas, Lucida Console, Courier New, serif;
	width: 100%;
	height: 150px;
}
textarea#interfaces[class="input"] {
	font-family: Consolas, Lucida Console, Courier New, serif;
	width: 100%;
	height: 50px;
}

@media screen and (min-width: 48em) {
	textarea[class="input"] {
		font-family: Consolas, Lucida Console, Courier New, serif;
		width: 100%;
		height: 200px;
	}
}

pre {
	text-align: left;
}

a,
a:visited {
	color: #4d6d9a;
	text-decoration: none;
	font-weight: bold;
}

.profile {
	text-align: right;
}
.profile a {
	padding-left: 10px;
}

.profile img {
	max-width: 30px;
	max-height: 30px;
}

a.ninja {
	color: #5f6366;
	cursor: auto;
}

.link {
	cursor: pointer;
}

pre {
	padding: 10px;
}

.link:hover {
	background-color: #f1f1f1;
	cursor: pointer;
}
